import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import UserMasterHeader from '../../../UserComponents/UserCommonComponent/UserMasterHeader';
import Row from 'react-bootstrap/Row';
import beforelogo from '../../../assets/registration-before.png';
import afterlogo from '../../../assets/login-after.png';
import UserMasterFooter from '../../../UserComponents/UserCommonComponent/UserMasterFooter';
import '../../../UserComponents/UserComponent.css';
import { useLocation, useNavigate } from 'react-router-dom';
import FirstPageAdmin from './FirstPageAdmin';
import SecondPageAdmin from './SecondPageAdmin';
import ThirdPageAdmin from './ThirdPageAdmin';
import AdminMasterHeader from '../../../CommonComponenets/AdminMasterHeader';
import AdminMasterFooter from '../../../CommonComponenets/AdminMasterFooter';
import { useEffect } from 'react';

const steps = ['Personal Information', 'Expectations about spouse', 'Photo'];

export default function StepperFormAdmin() {

 useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state?.state;
  const userStatus = JSON.parse(sessionStorage.getItem("status")); 

  // Define the initial step based on the user's status.
  const initialStep = () => {
    if (state === undefined) {
      switch (userStatus) {
        case 3:
          return 0;
        case 4:
          return 1;
        case 5:
          return 2;
        default:
          return 0; // Default to the first page for other statuses.
      }
    } else {
      return 0;
    }
  };

  const [activeStep, setActiveStep] = React.useState(initialStep);
  const [skipped, setSkipped] = React.useState(new Set());
  const [firstPageSubmitted, setFirstPageSubmitted] = React.useState(false);
  const [SecondPageSubmitted, setSecondPageSubmitted] = React.useState(false);
  const [ThirdPageSubmitted, setThirdPageSubmitted] = React.useState(false);

  //submit first page
  const handleFirstPageSubmit = () => {
    setFirstPageSubmitted(true);
    handleNext(); 
  };

  const handleSecondPageSubmit = () => {
    setSecondPageSubmitted(true);
    handleNext(); 
  };
  const handleThirdPageSubmit = () => {
    setThirdPageSubmitted(true);
    navigate('/admin/ad-fourthp') 
  };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div>
    <div><AdminMasterHeader /></div>
    <div className='stepper-container  p-md-4'  >
    <Box sx={{ width: '100%' }}>
    <Row className='mb-3 m-0'>
         <div style={{ display: "flex", justifyContent: "center" }}>
           <img src={beforelogo} height={40} />
         </div>
         <h1 className='mt-1 mb-0 pb-1' style={{ color: "rgb(57, 29, 28)" }}><b>Member Registration Form</b></h1>
         <div style={{ display: "flex", justifyContent: "center" }}>
           <img src={afterlogo} />
         </div>
       
       </Row>
      <Stepper activeStep={activeStep} className='main-step stepper-container'>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
   
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 5, mb: 1, textAlign: 'center' }}>
            <h3><b>All steps completed</b></h3> 
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset} style={{padding:"7px 19px", backgroundColor:"rgb(134, 28, 18)",border:"0px",color:"white",fontSize:"20px"}} >submit</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
        {activeStep === 0 && <FirstPageAdmin onSubmit={handleFirstPageSubmit}/>}
        {activeStep === 1 && <SecondPageAdmin onSubmit={handleSecondPageSubmit} />}
        {activeStep === 2 && <ThirdPageAdmin onSubmit={handleThirdPageSubmit} /> }
         
         { state === 1 ? ( <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
              className='backbtn'
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                {/* Skip */}
              </Button>
            )}

            <Button onClick={handleNext} className={ activeStep === 2 ? '': 'nextbtn'} disabled={activeStep === 2 }>   
              {activeStep === steps.length - 1 ? '' : 'Next'}
            </Button>
          </Box>
         ):null }
        </React.Fragment>
      )}
    </Box>
    </div>
    <div>
     <AdminMasterFooter />
    </div>
    
    </div>
  );
}