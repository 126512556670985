import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminMasterHeader from '../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminDashboard,adminDashboardEducation } from '../AllApi/AdminApi/AdminApi';
import '../AdminComponents/AdminLogin.css';

const AdminDashboard = () => {
  const navigate = useNavigate()
  const [record, setRecord] = useState([])
  const [record1, setRecord1] = useState([])

 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  //---------------------------------Disabled back button-------------------------------
  useEffect(() => {
    window.history.pushState(null, null, window.location.href)

    const handleBackButton = (event) => {
      event.preventDefault()
      window.history.forward()
    }

    window.addEventListener('popstate', handleBackButton)

    return () => {
      window.removeEventListener('popstate', handleBackButton)
    }
  }, [])

  //-------------------------- Authenticate User from session----------------------------------

  const isLoggedIn = !!sessionStorage.getItem('userdata')
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/admin')
    }
  }, [isLoggedIn, navigate])

  //--------------------------API data-------------------------------

  useEffect(() => {
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

     //API for Total & Todays members-------------------------------------------

    const fetchData1 = async () => {
      try {
        const response = await axios.get(adminDashboard, tokenConfig)
        
        setRecord(response.data)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
    fetchData1()
     
    //API for Education Category-----------------------------------------------
    
    const fetchData2 = async () => {
      try {
        const response = await axios.get(adminDashboardEducation,tokenConfig)
        setRecord1(response.data)   
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData2()
  }, [])



 


  //-------------------------CSS----------------------------

  const point1 = {
    backgroundColor: "#b6b5d6",
    backgroundImage: "linear-gradient(90deg, #b6b5d6 0%, #fddef9 100%)",
    borderRadius: '10px',
    color: 'black',
    height: '100px',
    boxShadow: '0px 2px 5px black',
    paddingTop: '20px',
  }
  const point2 = {
    backgroundColor: "#bffbfa",
    backgroundImage: "linear-gradient(90deg, #bffbfa 0%, #fce0ea 100%)",
    borderRadius: '10px',
    color: 'black',
    height: '100px',
    boxShadow: '0px 2px 5px black',
    paddingTop: '20px',
  }
  const point3 = {
    backgroundColor: "#0f9ae5",
    backgroundImage: "linear-gradient(90deg, #0f9ae5 0%, #79cdc9 100%)",
    borderRadius: '10px',
    color: 'white',
    height: '100px',
    boxShadow: '0px 2px 5px black',
    paddingTop: '20px',
  }
 const tableHead={
  backgroundColor: "#462f46",
  color:"white,"
 }

  const background = {
    backgroundColor: 'white',
    height: '100%',
    botton: '0',
  }

  function changeBackground(e) {
    e.target.style.boxShadow = '0px 2px 20px black'
  }
  function changeBackground1(e) {
    e.target.style.boxShadow = '0px 2px 5px black'
  }

  return (
    <>
      <div className="row g-0" style={background}>
        <div>
          {' '}
          <AdminMasterHeader />{' '}
        </div>

           {/* Todays Count */}
            <div className='row m-0 mt-md-5' style={{display:"flex",justifyContent:"center"}}>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                onMouseOver={changeBackground}
                onMouseOut={changeBackground1}
                style={point1}
              >
                Todays Male<br />
                {record.todaysMaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                onMouseOver={changeBackground}
                onMouseOut={changeBackground1}
                style={point1}
              >
                Todays Female<br />
                {record.todaysFemaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                onMouseOver={changeBackground}
                onMouseOut={changeBackground1}
                style={point1}
              >
                Todays Payment<br />
                Rs. {" "} {record.todaysPaymentSum}
              </div>

            </div>

            {/* Total Count */}
            <div className='row m-0' style={{display:"flex",justifyContent:"center"}}>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                onMouseOver={changeBackground}
                onMouseOut={changeBackground1}
                style={point1}
              >
                Total Male<br />
                {record.totalMaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                onMouseOver={changeBackground}
                onMouseOut={changeBackground1}
                style={point1}
              >
                Total Female<br />
                {record.totalFemaleCount}
              </div>

                <div
                className="col-12 col-md-3 my-3 mx-md-5  text-center"
                onMouseOver={changeBackground}
                onMouseOut={changeBackground1}
                style={point1}
              >
                Total Payment<br />
                Rs. {" "} {record.totalPaymentSum} 
              </div>

            </div>

              {/* ====Other Count ============= */}
              <div className='row m-0 pb-5' style={{display:"flex",justifyContent:"center"}}>

                  <div
                  className="col-12 col-md-3 my-3 mx-md-5  text-center"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground1}
                  style={point3}
                  >
                  First Marriage Melava<br />
                  {record.FirstMarriageMelava}
                  </div>

                  <div
                  className="col-12 col-md-3 my-3 mx-md-5  text-center"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground1}
                  style={point3}
                  >
                  Vidhava Vidhoor Melava<br />
                  {record.VidhavaVidhooMelava}
                  </div>

                  <div
                  className="col-12 col-md-3 my-3 mx-md-5  text-center"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground1}
                  style={point3}
                  >
                  Both Melava<br />
                   {record.BothMelava} 
                  </div>

                  <div
                  className="col-12 col-md-3 my-3 mx-md-5  text-center"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground1}
                  style={point2}
                  >
                  Payment Success<br />
                 {" "} {record.paymentSuccess} 
                  </div>

                  <div
                  className="col-12 col-md-3 my-3 mx-md-5  text-center"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground1}
                  style={point2}
                  >
                  Payment Pending<br />
                  {" "} {record.paymentPending} 
                  </div>

                  <div
                  className="col-12 col-md-3 my-3 mx-md-5  text-center"
                  onMouseOver={changeBackground}
                  onMouseOut={changeBackground1}
                  style={point2}
                  >
                  Payment Approval Pending<br />
                  {" "} {record.adminApproval} 
                  </div>

              </div>

            {/* Education Category */}
            <div className='row m-0 pb-5 education' style={{display:"flex",justifyContent:"center",backgroundColor:"#d1d1d4"}}>

              <div className="col-12 col-md-6" style={{display:"flex",justifyContent:"center",}}>
              <table className='mt-5 bg-white'>
                  <thead>
                  <tr style={tableHead}>
                  <th>Education Category</th>
                    <th>Total Count</th>
                  </tr>
                  </thead>

                  <tbody>
                  {record1.map((item, index) => {
                      return (
                    <tr key={index}>
                      <td> {item.education_name}</td>
                      <td>{item.count}</td>
                    </tr>
                    )
                    })}
                    <tr style={{color:"red"}}>
                      <td>Total Registration</td>
                      <td>{record.totalMaleCount+record.totalFemaleCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>


              <div className="col-12 col-md-6" style={{display:"flex",justifyContent:"center",}}>
              <table className='mt-5 bg-white'>
                  <thead style={{width:"300px",justifyContent:"center"}}>
                    <tr style={tableHead}>
                    <th>Marital Status</th>
                    <th>Male</th>
                    <th>Female</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>Unmarried</td>
                      <td>{record.UnmarriedMaleCount}</td>
                      <td>{record.UnmarriedFemaleCount}</td>
                    </tr>
                    <tr>
                      <td>Divorcee</td>
                      <td>{record.DivercyMaleCount}</td>
                      <td>{record.DivercyFemaleCount}</td>
                    </tr>
                    <tr>
                      <td>Widower/Widow</td>
                      <td>{record.VidowMaleCount}</td>
                      <td>{record.VidowFemaleCount}</td>
                    </tr>
                    <tr>
                      <td>Disabled</td>
                      <td>{record.totalMaleDisabledCount}</td>
                      <td>{record.totalFemaleDisabledCount}</td>
                    </tr>
                  </tbody>
                </table>
              </div>


            </div>
      
      </div> 
    </>
  )
}

export default AdminDashboard
