import React, { useState ,useEffect} from 'react'
import './Members.css'
import AdminMasterHeader from '../../CommonComponenets/AdminMasterHeader'
import axios from 'axios';
import { adminDisabilityList,adminDisabilityApproval,adminMemberList, RegistrationSelect, paymentApproveFindall, paymentApproveModalData, paymentApproveStatusChange, memberImagePath, adharImagePath, taxImagePath } from '../../AllApi/AdminApi/AdminApi';

import { toast } from 'react-toastify';
import { Row, Table,Col } from 'react-bootstrap';
import logo from '../../assets/kolLogo.png'
import logo1 from '../../assets/logo2.png'
import { MDBDataTable } from 'mdbreact';
import AdminMasterFooter from '../../CommonComponenets/AdminMasterFooter';

const PaymentApproval = ({}) => {

   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
// ----------------------------------------------------------------

  const [record, setRecord] = useState([])
  const [record1, setRecord1] = useState([])
  const [record2, setRecord2] = useState('')
  const [showLargePhoto, setShowLargePhoto] = useState(false);
  const [isViewingProfile, setIsViewingProfile] = useState(false)

   // State for the checkboxes
   const [adharChecked, setAdharChecked] = useState(false);
   const [paymentChecked, setPaymentChecked] = useState(false);

   // Function to handle checkbox change
  const handleAdharChange = () => {
    setAdharChecked(!adharChecked);
  };

  const handlePaymentChange = () => {
    setPaymentChecked(!paymentChecked);
  };

  // Check if both checkboxes are selected
  const bothChecked = adharChecked && paymentChecked;

 
   //----------------------------------API code------------------------------------------
   
  
    const tokenValue = sessionStorage.getItem('token')

    const tokenConfig = {
      headers: {
        token: tokenValue,
      },
    }

    const fetchMemberData = async () => {
      try {
        const response = await axios.get(paymentApproveFindall, tokenConfig);
        setRecord(response.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
   
    useEffect(() => {
      fetchMemberData();
    }, [])

  //--------------------------Image click code-----------------------------------

  const fetchMemberData1 = async (record1Id) => {
    try {
      const response = await axios.get(`${paymentApproveModalData}${record1Id}`);
      
        const data=response.data[0]
        
       
      setRecord1(data);

    } catch (error) {
    console.error('Error fetching data:', error);
      }
    };
    

  const handleImageClick = (member) => {    
    setIsViewingProfile(true);
    setShowLargePhoto(true);
    fetchMemberData1(member.member_id);
    setRecord2(member);
  }

  const handleCloseLargePhoto = () => {
    setShowLargePhoto(false);
    setIsViewingProfile(false);
    setAdharChecked(false);
    setPaymentChecked(false);

  };
 
  const handleOperationClick = (operation) => {
    let updatedPaymentStatus 
    if (operation == 'approve') {
      updatedPaymentStatus = "2";

    } else if (operation == 'reject') { 
      updatedPaymentStatus = "3";
    }  
   
    const objData={
      member_id:record1.member_id,
      reg_id:record1.reg_id,
      pay_amount:record1.pay_amount,
      status:updatedPaymentStatus
      
    }
  
    
 
    axios.put(paymentApproveStatusChange,objData)
    .then((res) => {
    
      if (res.data.status == "REJECT") {
        toast.success("Payment rejected and email sent")
        fetchMemberData()
      }
      else if (res.data.status == "APPROVE") {
        toast.success("Payment approved and email sent")
        fetchMemberData()
      }
    })
    .catch((err) => console.log(err))
    handleCloseLargePhoto();
  }; 

  
//CSS for Information-------------------------------------------------------------------
const info = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: '5px',
  backgroundColor: '#b3b3d6',
  color: 'black',
}
//=====================================DATA TABLE============================================

const columns = [
  {
    label: 'Sr.no',
    field: 'srNo',
    sort: 'asc',
  },
  {
    label: 'Member Id',
    field: 'member_id',
    sort: 'asc',
  },
  {
    label: 'Reg.Id',
    field: 'reg_id',
    sort: 'asc',
  },
  {
    label: 'Name',
    field: 'name',
    sort: 'asc',
  },
  {
    label: 'Gender',
    field: 'gender',
    sort: 'asc',
  },
  {
    label: 'Email Id',
    field: 'email_id',
    sort: 'asc',
  },
  {
    label: 'Mobile No',
    field: 'mobileno',
    sort: 'asc',
  },
  {
    label: 'View Profile',
    field: 'action',
  },
];


const customRows = record.map((item, index) => {
  const { member_id,reg_id,gender, name, mobileno, email_id, } = item;
  const genderLabel = gender === 1 ? 'Male' : 'Female';
  return {
    srNo: index + 1,
    member_id,
    reg_id,
    name,
    gender:genderLabel,
    mobileno,
    email_id,
    action: (
      <button className="viewButtons1" type='submit' onClick={() => handleImageClick(item)}>View Profile</button>
      
    ),
  };
});

//=====================================================================================================

  return (
    <>
    <div className="row m-0">
      <div className='p-0'> <AdminMasterHeader/> </div>
      <div className="filter-buttons row">

        <div className='col-12'>
        <h2 style={{ fontWeight: '550',textAlign:"start"}}>Payment Approval</h2>
        </div>
        </div>


     <Row className='row p-0 m-0' style={{justifyContent:"center",display:"flex",textAlign:"center"}}>
    <div className="col-12 col-sm-1"></div>
    <div className={`col-12 col-sm-10 ${showLargePhoto ? 'hide-pagination' : ''}`}>
        <MDBDataTable
          className='mb-5 custom-datatable'
          striped
          bordered
          hover
          small
          data={{ columns, rows: customRows }}
          style={{ textAlign: "center", fontSize: "14px" ,}}/>
    </div>
    <div className="col-12 col-sm-1"></div>
    </Row>


    {showLargePhoto && (
          <div className="large-photo-modal">
            <div className="modal-content" style={{backgroundColor:"#d1d1d4"}}>
              <Row className="header m-0" style={{ borderBottom: '3px solid',backgroundColor:"#fff" }}>

                <Col md={3} sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={logo}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>

                <Col md={5}  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div className="text-center">
                    <h5 className="fw-bolder">दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
                    <h3 className="fw-bolder">
                    दिगंबर जैन बोर्डिंग, कोल्हापूर{' '}
                    </h3>
                    <h5 className="fw-bolder">जैन वधू - वर नोंदणी 2024</h5>
                  </div>
                </Col>

                <Col md={3}  sm={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <div>
                    <img
                      src={logo1}
                      style={{ mixBlendMode: 'darken' }}
                      alt=""
                      width={160}
                      height={130}
                    />
                  </div>
                </Col>


                <Col md={1} sm={12}>
                      <span 
                      title="Close"
                      className="close ms-auto fs-1 me-3"
                      onClick={handleCloseLargePhoto}
                    >
                      &times;
                    </span>
                </Col>

              </Row>

              {isViewingProfile && (
                <div className="row profile-details m-0">

                  <div  className="col-12 col-md-3"
                    style={{
                      justifyContent: 'start',
                      display: 'flex',
                      flexDirection: 'column',margin:"19px 0px 0px 0px",
                    }}
                  >
                
                  <img
                    src={`${memberImagePath}/${record1.image}`}
                    alt={`${record1.en_fname}'s Photo`} style={{height:"250px",backgroundColor:"white"}}
                    className="small-photo mb-2 border "
                  />  <br/>

                  <img
                    src={`${adharImagePath}/${record1.adharcard_image}`}
                    alt={`${record1.en_fname}'s Adhar Photo`} style={{height:"450px",backgroundColor:"white"}}
                    className="small-photo mb-2 border hover-enlarge"
                  /> <br/>

                  <img 
                    src={`${taxImagePath}/${record2.tax_image}`}
                    alt={`${record1.en_fname}'s Tax Photo`} style={{height:"400px",backgroundColor:"white",}}
                    className="small-photo mb-2 border hover-enlarge"
                  />  

                  <div className='row px-2 pb-2'>
                    <div className='p-2'  style={{backgroundColor:"white"}}>
                        <div className='col-12 py-2'>
                            <span><b>UTR No./UPI No./Transaction Transfer No: </b></span> <span>{" "}{record2.taxid}</span>
                        </div>
                        <div className='col-12 py-2'>
                            <span><b>Transaction Date : </b></span> <span>{" "}{record2.pay_date}</span>
                        </div>
                        <div className='col-12 py-2'>
                            <span><b>Transaction Amount: </b></span> <span>{" "}{record2.pay_amount}</span>
                        </div>
                    </div>
                  </div>

                
                  </div>

                  <div className="col-12 col-md-9 pt-3">
                    {/* Basic Information */}
                    <div
                      className="row m-0 border"
                      style={{ backgroundColor: '#fff' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Basic Information/मुलभूत माहिती</h6>
                      </div>
                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>First Name (English)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{record1.en_fname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Middle Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Last Name (English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.en_lname}
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-12 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>पहिले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_fname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>मधले नाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{record1.mr_mname}</div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>आडनाव (मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_lname}
                          </div>
                        </div>
                      </div>

                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Gender/लिंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "} {record1.gender === 1 ? 'Male' : 'Female'}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Marital Status/वैवाहिक स्थिती</p>
                          </div>
                          <div className="col-6 col-md-8">
                              :{" "}
                              {record1.marital_status === 1
                                ? "Unmarried (Single)"
                                : record1.marital_status === 2
                                ? "Divorcee"
                                : record1.marital_status === 3
                                ? "Widow"
                                : "Unknown"}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Complextion/रंग</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.complextion}
                          </div>
                        </div>

                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Disability/दिव्यांग</p>
                          </div>
                          <div className="ccol-6 col-md-8">
                            :{' '}{record1.disability === "1" ? 'No' : 'Yes'}
                          </div>
                        </div>

                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{backgroundColor:"yellow"}}>Melava/मेळावा</p>
                          </div>
                          <div className="col-6 col-md-8">
                                    <span className='py-1' style={{backgroundColor:"yellow"}}>
                                    :{" "}
                                    {record1.melava_type === 1
                                    ? "Single Melava"
                                    : record1.melava_type === 2
                                    ? "Vidhva-Vidhoor Melava"
                                    : record1.melava_type === 3
                                    ? "Both Melava"
                                    : "Unknown"}
                                    </span>
                          </div>
                        </div>
                        
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Date of birth/जन्मतारीख</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "}{record1.dob} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Birth time/जन्मवेळ</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.birth_time}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Height/उंची</p>
                          </div>
                          <div className="col-6 col-md-8"> :{" "}{record1.height} </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Subcast/पोटजात</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.subcastename}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Education Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#fff' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Education & Occupation Information/शिक्षण आणि व्यवसाय माहिती</h6>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Education/शिक्षण</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.education_name}
                          </div>
                        </div>
                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Education Category/शिक्षण श्रेणी
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.subeducation_name}
                          </div>
                        </div>
                        <div className="col-12 col-md-6  d-flex">
                          <div className="col-6 col-md-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Occupation Type/ व्यवसायाचा प्रकार
                            </p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.occupation}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Annual Income/वार्षिक उत्पन्न</p>
                          </div>
                          <div className="col-6 col-md-8">:{" "} {record1.amount} </div>
                        </div>
                      </div>

                      <div className="row d-flex">
                        <div className="col-6 col-md-4 para">
                          <p style={{ lineHeight: '18px' }}>
                            Occupation & Addrress/व्यवसाय आणि पत्ता
                          </p>
                        </div>
                        <div className="col-6 col-md-8">
                          :{' '}
                          {record1.occupation_detail}
                        </div>
                      </div>
                    </div>

                    {/* Family Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#fff' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Family Information/कौटुंबिक माहिती</h6>
                      </div>
                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father First Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.en_mname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Middle Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                          :{" "}{record1.en_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>Father Last Name(English)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.en_lname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_fatherfname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे मधले नाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{record1.mr_fathermname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-6 col-md-4 para">
                            <p>वडिलांचे आडनाव(मराठी)</p>
                          </div>
                          <div className="col-6 col-md-8">
                            :{' '}
                            {record1.mr_fatherlname}
                          </div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Acar/शेत जमीन एकर</p>
                          </div>
                          <div className="col-6">:{" "}{record1.farm_ac}</div>
                        </div>
                        <div className="col-12 col-md-6 d-flex">
                          <div className="col-6 para">
                            <p>Farm in Guntha/शेत जमीन गुंठे</p>
                          </div>
                          <div className="col-6">:{" "}{record1.farm_g}</div>
                        </div>
                        <div className="col-12 d-flex">
                          <div className="col-6 col-md-3 para">
                            <p>Other Property/इतर मालमत्ता</p>
                          </div>
                          <div className="col-6 col-md-9">
                          :{" "}{record1.other_property}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Brother/भाऊ:</p>
                          </div>
                            <div className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.b_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.b_married}
                              </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="col-12 para">
                            <p>Sister/बहीण:</p>
                          </div>
                            <div  className='d-flex' style={{textAlign:"center"}}>
                              <div className="col-6">
                                Unmarried/अविवाहित : {record1.s_unmarried}
                              </div>
                              <div className="col-6">
                                Married/विवाहित : {record1.s_married}
                              </div>
                            </div>
                        </div>
                      </div>
                    </div>

                    {/* Contact Information */}
                    <div
                      className="row m-0 border mt-3"
                      style={{ backgroundColor: '#fff' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Contact Information/संपर्क माहिती</h6>
                      </div>
                      <div
                        className="col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3  para">
                            <p>Address(English)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {record1.en_address}{' '}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>Village/City(English)</p>
                          </div>
                          <div className="col-8 ps-3">
                          :{" "}{record1.en_village}
                          </div>
                        </div>
                        <div className="col-12 col-md-7 d-flex">
                          <div className="col-3 para">
                            <p>पत्ता(मराठी)</p>
                          </div>
                          <div className="col-9 ps-2">
                          :{" "} {record1.mr_address}
                          </div>
                        </div>
                        <div className="col-12 col-md-5 d-flex">
                          <div className="col-4 para ms-md-2">
                            <p>गाव/शहर(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {record1.mr_village}{' '}
                          </div>
                        </div>
                      </div>

                      <div
                        className=" col-12 p-2 d-flex"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>State(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {record1.stateenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>District(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                            :{' '}
                            {record1.districtenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>Taluka(English)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.talukaenname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>राज्य(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.statemrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>जिल्हा(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.districtmrname}
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p>तालुका(मराठी)</p>
                          </div>
                          <div className="col-8 ps-2">
                          :{" "}{record1.talukamrname}
                          </div>
                        </div>
                      </div>
                      <div
                        className=" col-12 d-flex p-2"
                        style={{ flexWrap: 'wrap' }}
                      >
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>Email/ईमेल</p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{record1.email_id}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-1/मोबाईल नंबर-1
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{record1.mobileno}</p>
                          </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex">
                          <div className="col-4 para">
                            <p style={{ lineHeight: '18px' }}>
                              Mobile No-2/मोबाईल नंबर-2
                            </p>
                          </div>
                          <div className="col-8">
                            <p>:{" "}{record1.mobileno2}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Expectations about spouse */}
                    <div
                      className="row m-0 border mt-3 mb-3"
                      style={{ backgroundColor: '#fff' }}
                    >
                      <div className="col-12 border-bottom" style={info}>
                        <h6>Expectations About Spouse/जोडीदाराबद्दल अपेक्षा</h6>
                      </div>
                      <div className=" col-12 p-2">
                        <div className="row d-flex">
                          <div className="col-5 col-md-3  para">
                            <p>Expectations/अपेक्षा:</p>
                          </div>
                          <div className="col-7 col-md-9"> <p>{record1.exp_housewife === 1 ? 'गृहिणी' : null} &nbsp; {record1.exp_compatible === 1 ? 'अनुरूप' : null} &nbsp; {record1.exp_farmer === 1 ? 'शेती' : null}{" "}{record1.exp_serviceman === 1 ? 'नोकरी' : null} &nbsp; {record1.exp_businessman === 1 ? 'व्यवसाय' : null} &nbsp; </p></div>
                        </div>
                      </div>
                    </div>

                {/* ==============buttons for Approve & Reject================== */}
                    <div className='row m-0 my-3 py-2'>
                        <div className="col-12 row">
                           <div className='col-3'></div>
                           <div className='col-6 justify-content-center d-flex py-1' style={{color:"white",backgroundColor:"#462f46"}}>
                              <div className="form-check  mx-2">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="adharCheck"
                                    checked={adharChecked}
                                    onChange={handleAdharChange}
                                  />
                                  <label className="form-check-label" htmlFor="adharCheck">
                                    Adhar Check
                                  </label>
                                </div>

                                <div className="form-check  mx-2">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="paymentCheck"
                                    checked={paymentChecked}
                                    onChange={handlePaymentChange}
                                  />
                                  <label className="form-check-label" htmlFor="paymentCheck">
                                    Payment Check
                                  </label>
                                </div>
                           </div>
                           <div className='col-3'></div>
                        </div> 

                        <div className="col-12 text-center">
                            <div className="mt-3">
                              <button
                                className="btn btn-success mx-2"
                                disabled={!bothChecked} onClick={() => handleOperationClick('approve')}
                              >
                                Approve
                              </button>

                              <button
                                className="btn btn-danger ml-2 mx-2"
                                disabled={!adharChecked && !paymentChecked} onClick={() => handleOperationClick('reject')}
                              >
                                Reject
                              </button>
                            </div>
                       </div>
                      
                    </div>



                  </div>

                </div>
              )}
            </div>
          </div>
        )}

    </div>
    <AdminMasterFooter/>
    </>
  );
};

export default PaymentApproval;






