import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Spinner } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { RegistrationSelect, RegistrationUpdate, RegistrationUpdateProfile } from '../../AllApi/UserApi/UserApi';

const SecondPage = (props) => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [update, setUpdate] = useState(false);

  const data = JSON.parse(sessionStorage.getItem('status'));

  const [Compatible, setCompatible] = useState(0);
  const [Businessman, setBusinessman] = useState(0);
  const [Serviceman, setServiceman] = useState(0);
  const [Farmer, setFarmer] = useState(0);
  const [HouseWife, setHouseWife] = useState(0);

  const [isLoading, setIsLoading] = useState(false); // Add isLoading state

  const userdata = JSON.parse(sessionStorage.getItem('userdata'));
  const member_id = userdata.id;

  const handleSave = (e) => {
    setIsLoading(true)

    if (selectedOptions.length === 0) {
      setIsLoading(false);
      toast.error('Please select at least one option.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Stop the update action
    }

    e.preventDefault();
    const regdata = {
      member_id: member_id,
      exp_compatible: Compatible,
      exp_businessman: Businessman,
      exp_serviceman: Serviceman,
      exp_farmer: Farmer,
      exp_housewife: HouseWife,
    };

    axios
      .put(RegistrationUpdate, regdata)
      .then((res) => {
        setIsLoading(false); 
        if (res.data.message === 'UPDATED') {
          // alert('Saved Successfully');
          toast.success('Saved Succesfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.setItem("status", 5);
          props.onSubmit();
        }
      })
      .catch((err) => console.log(err));
  };

  const updatedata = () => {
    setIsLoading(true)

    if (selectedOptions.length === 0) {
      setIsLoading(false);
      toast.error('Please select at least one option.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return; // Stop the update action
    }

    const regdata = {
      member_id: member_id,
      exp_compatible: Compatible,
      exp_businessman: Businessman,
      exp_serviceman: Serviceman,
      exp_farmer: Farmer,
      exp_housewife: HouseWife,
    };
    axios
      .put(RegistrationUpdate,regdata)
      .then((res) => {
        setIsLoading(false);
        if (res.data.message === 'UPDATED') {
          // alert('Profile UPDATED Successfully');
          toast.success('Profile Updated Succesfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // alert('Failed To Update Package, try again');
        toast.error('Failed To Update Package, try again!', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      });

    
  };
  const [isEditing, setIsEditing] = useState(true);

  const handleEditClick = () => {
    setIsEditing(true);
    setUpdate(true);
  };

  const [userStatus, setUserStatus] = useState(null);

  const getdata = () => {
    axios
      .get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        if (res.data.json !== null) {
          const api = res.data;
          api.map((api, index) => {
            setHouseWife(api.exp_housewife);
            setCompatible(api.exp_compatible);
            setBusinessman(api.exp_businessman);
            setServiceman(api.exp_serviceman);
            setFarmer(api.exp_farmer);
            let expdata=[]
            
            if(api.exp_housewife == 1){
              expdata.push("HouseWife")
            }
            if(api.exp_compatible == 1){
              expdata.push("Compatible")
            }
            if(api.exp_businessman == 1){
              expdata.push("Businessman")
            }
            if(api.exp_serviceman == 1){
              expdata.push("Serviceman")
            }
            if(api.exp_farmer == 1){
              expdata.push("Farmer")
            }

            if(expdata.length >0){
              setSelectedOptions(expdata)
            }
            
            
          });
          setIsEditing(false);
          setUpdate(true)
          
        }
      })
      .catch((error) => {
        // Handle error
      });
  };

  useEffect(() => {
    setUserStatus(data);
    if (data >4) {
      getdata();
    }
  }, [data]);

  const handleCheckboxChange = (event, option) => {
    if (event.target.checked) {
      if (selectedOptions.length < 5) {
        setSelectedOptions([...selectedOptions, option]);
      } else {
        // alert('You can select only two options.');
        toast.error('You can select only two options.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        event.target.checked = false;
      }
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    }
  
    if (option === 'Farmer') {
      setFarmer(event.target.checked ? 1 : 0);
    }
    if (option === 'Businessman') {
      setBusinessman(event.target.checked ? 1 : 0);
    }
    if (option === 'Compatible') {
      setCompatible(event.target.checked ? 1 : 0);
    }
    if (option === 'HouseWife') {
      setHouseWife(event.target.checked ? 1 : 0);
    }
    if (option === 'Serviceman') {
      setServiceman(event.target.checked ? 1 : 0);
    }

    
  };

  return (
    <>
      <Container className='container-second rounded p-3 my-3 p-md-5'>
        <div
          className=' pe-5 '
          style={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          { userStatus > 4 ? (
            <button
              className='rounded mt-0 '
              style={{
                padding: '6px 15px',
                backgroundColor: 'rgb(233, 224, 227)',
                border: '0px',
                color: 'rgb(134, 28, 18)',
                marginBottom: '15px',
                marginTop: '-19px',
                fontSize: '20px',
              }}
              
              onClick={handleEditClick}
            >
              <b>Edit</b>
            </button>
          ) : null}
        </div>
        <Form onSubmit={handleSave}>
          <div
            className=' rounded shadow p-3 pb-2 py-sm-5 '
            style={{ backgroundColor: 'white' }}
          >
            <Row style={{ paddingTop: '10px' }}>
              <Col>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: '',
                    paddingBottom: '10px',
                    borderBottom: '1px solid gray',
                    color: 'rgb(134, 28, 18)',
                  }}
                  

                >
                  <h5>
                    <b>Expectations about spouse - जोडीदाराबाबत अपेक्षा</b>
                  </h5>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    paddingTop: '10px',
                    color: 'rgb(134, 28, 18)',
                  }}
                >
                </div>
              </Col>
            </Row>
            <Row className='px-2' style={{ paddingTop: '10px' }}>
              {userdata && userdata.gender === 1 ? (
                <Col sm={12} md={3}>
                  <label
                    className='mx-4 pt-3'
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                  
                    <input
                      type='checkbox'
                      name='HouseWife'
                      value='1'
                      disabled={isEditing ? false : true}
                      onChange={(event) =>
                      handleCheckboxChange(event, 'HouseWife')}  
                      defaultChecked={selectedOptions}

                     checked={selectedOptions.includes('HouseWife')}
                    />
                    <h5 className='ms-2'>Homemaker/ गृहिणी</h5>
                  </label>

                </Col>
              ) : null}
              <Col sm={12} md={3}>
                <label
                  className='mx-4 pt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='Compatible'
                    value='1'
                    disabled={isEditing ? false : true}
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Compatible')
                    }
                    checked={selectedOptions.includes('Compatible')}
                  />
                  <h5 className='ms-2'>Compatible/अनुरूप</h5>
                </label>
              </Col>
              <Col sm={12} md={2}>
                <label
                  className='mx-4 pt-3 '
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='businessman'
                    value='1'
                    disabled={isEditing ? false : true}
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Businessman')
                    }
                    checked={selectedOptions.includes('Businessman')}
                  />
                  <h5 className='ms-2'>Business/व्यवसाय</h5>
                </label>
              </Col>
              <Col sm={12} md={2}>
                <label
                  className='mx-4 pt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='serviceman'
                    value='1'
                    disabled={isEditing ? false : true}
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Serviceman')
                    }
                    checked={selectedOptions.includes('Serviceman')}
                  />
                  <h5 className='ms-2'>Service/नोकरी</h5>
                </label>
              </Col>
              {userdata && userdata.gender === 2 ? (
              <Col sm={12} md={2}>
                <label
                  className='mx-4 pt-3'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <input
                    type='checkbox'
                    name='farmer'
                    value='1'
                    disabled={isEditing ? false : true}
                    defaultChecked={selectedOptions}
                    onChange={(event) =>
                      handleCheckboxChange(event, 'Farmer')
                    }
                    checked={selectedOptions.includes('Farmer')}
                  />
                  <h5 className='ms-2'>Farmer/शेतकरी</h5>
                </label>
              </Col>
              ) : null}
            </Row>
            <Row>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'rgb(134, 28, 18)',
                  paddingTop: '30px',
                }}
              >
                 {isLoading ? (
              <Spinner animation="border" variant="primary" /> // Show Spinner while loading
            ) : update === false ? (
                  <button
                    type='submit'
                    className='rounded py-2 px-4'
                    style={{
                      backgroundColor: 'rgb(134, 28, 18)',
                      border: '0px',
                      color: 'white',
                      fontSize: '20px',
                    }}
                  >
                    Save
                  </button>
                ) : null}
              </Col>
            </Row>
          </div>
        </Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
           {isLoading ? (
              <Spinner animation="border" variant="primary" /> // Show Spinner while loading
            ) : update === true ? (
            <button
              onClick={updatedata}
              className='rounded mb-0'
              style={{
                padding: '6px 16px',
                backgroundColor: 'rgb(233, 224, 227)',
                border: '0px',
                color: 'rgb(134, 28, 18)',
                fontSize: '20px',
                marginTop: '20px',
                marginBottom: '-14px',
              }}
            >
              <b>Update</b>
            </button>
          ) : null}
        </div>
      </Container>
    </>
  );
};

export default SecondPage;
