import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import '../UserComponents/UserComponent.css';
import { useNavigate } from 'react-router-dom';
import { green } from '@mui/material/colors';
import afterlogo from '../assets/login-after.png';
import Card from 'react-bootstrap/Card';
import { RegistrationSelect, RegistrationStop } from '../AllApi/UserApi/UserApi';
import axios from 'axios';
import UserMasterHeader from '../UserComponents/UserCommonComponent/UserMasterHeader';
import UserMasterFooter from '../UserComponents/UserCommonComponent/UserMasterFooter';
import { toast } from 'react-toastify';
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import blink from '../assets/photo_rejected.gif'
import { Table } from 'react-bootstrap';
import { memberImagePath } from '../AllApi/AdminApi/AdminApi';

const MainPage = () => {

   useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const member_id = userdata.id


  const [Userdata, setUserdata] = useState()
  const [lockDateOk,setlockDateOk]=useState(false)   




  const getUserData = () => {


    axios.get(`${RegistrationSelect}${member_id}`)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getUserData()
  }, [])

  // -----------handle checkbox-----------
  const navigate = useNavigate()
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleNextClick = () => {

    if (isChecked) {


      if (Userdata && Array.isArray(Userdata)) {


        Userdata.forEach((value) => {
          if ((value.marital_status === "Widow" && value.gender === 2) || value.disability === "2") {
            if ((value.marital_status === "Widow" && value.gender === 2)) {
             
              toast.success('Your Recipst has been sent to Your Mail!', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
            else if (value.disability === "2") {
              toast.success('Your Recipit Will be Send On Mail After Admin Approval !', {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }

          }
          else {
            navigate('/payment2');
          }
        }
        );

      }

    }
  }

  // =====================Header=====================
  

  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");    
  }

  useEffect(() => {
    let token=sessionStorage.getItem("token");
    let userdata=sessionStorage.getItem("userdata");

    if(token==null || userdata==null){
      sessionStorage.clear()
      navigate("/")
    }
  }, [])

  const regFun = () => {
    const state = 1
    navigate('/user/regForm', { state: { state } })

  }



  

  //--------------------------CSS---------------------------------
  const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "14px" }
  const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "14px" }
  const style5 = {display:"flex",justifyContent:"center", lineHeight: "15px", fontSize: "12px",flexDirection:"column" }

  return (

    <Container fluid className='m-0 p-0'>
      <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
        <div><UserMasterHeader /></div>

      <Navbar expand="lg" style={{backgroundColor: "#660505"}}>
      <Container fluid>
        <Navbar.Brand href="#"></Navbar.Brand>

          <Nav
            className="ms-auto my-2 my-lg-0"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            <Nav.Link href="/#/paymentRecipt" className=' mx-2 text-white fs-5 '><b>View Receipt</b></Nav.Link>
          </Nav>
          
      </Container>
    </Navbar>
       

    </Container> 
    </div>

      <Container className='container-fourth rounded p-3 my-3 p-md-5 '>
        <div className='rounded shadow  px-3 pb-2 py-sm-5' style={{ backgroundColor: "white" }} >
          <Row className='mb-2'>
            <Col>
              <div className='mt-5 mt-md-0' style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "red" }}>
                <h5><b>The following text is to be printed in the main booklet as it is. Please make sure before proceeding... <br /> (खालील मजकूर आहे तसा मुख्य पुस्तिकेमध्ये छापला जाणार आहे. कृपया पुढे जाण्याअगोदर खात्री करावी...)</b></h5>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={afterlogo} />
              </div>
            </Col>
          </Row>



          {
            Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

              return (
                <>

                <Row style={{overflow:"auto"}}>
                  {/* <Table striped bordered style={{borderColor:"rgb(250, 99, 18)"}}>
              <thead className='text-center' style={{fontSize:"14px",backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )"}}>
        <tr>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उमेदवाराचे नाव</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>शिक्षण </th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>नोकरी/व्यवसाय/शेती</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>जन्मतारीख व वेळ</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उंची</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>देह/वर्ण</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>पोटजात/भाऊ/बहिण</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>अपेक्षा</th>
                  <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>फोटो</th>
                </tr>
              </thead>
              <tbody className='text-center' style={{fontSize:"13px"}}>
              {
                    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

                      return (
                        <>
                <tr>
                  <td className='text-start' style={{  minWidth: '250px' ,borderColor:"rgb(250, 99, 18)"}}><p style={{ fontWeight: "bold",color:"red"}}>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname} </p>
                  <p style={{ fontSize: "14px",marginTop:"-12px"}}>पत्ता : {' '} {value.mr_address}<br />गाव/ शहर : {value.mr_village}{' '}&nbsp;ता : {value.talukamrname} <br />जि : {value.districtmrname}{' '}&nbsp; रा : {value.statemrname}<br />
                  मो :{' '}{value.mobileno}/{value.mobileno2}
                  </p>
                  </td>
                  <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '50px'}}>{value.subeducation_name},&nbsp;<br />{value.other_education}</td>
                  <td style={{borderColor:"rgb(250, 99, 18)", minWidth: '150px'}}>व्यवसाय :{' '}{value.occupation},<br />{value.occupation_detail} <br />
                  वा.प्रा :{' '}{value.amount}<br />
                    { value.farm_g > 0 || value.farm_ac > 0 ? (
                      <>
                        शेती -&nbsp;
                        {value.farm_ac > 0 ? (
                        <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                        ) : null}
                        {value.farm_g > 0 ? (
                          <span >{' '}&nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                          ) : null
                        }
                      </>
                    ) : null}<br />
                    {value.other_property}
                  </td>
                  <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '40px'}}>{value.dob}<br />{value.birth_time}</td>
                  <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '30px'}}>{value.height}</td>
                  <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '70px'}}>{value.complextion}</td>
                  <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '80px'}}>{value.subcastename}<hr className='m-0 p-1' />
                  <div>भाऊ :</div> 
                          <span >विवाहित-{value.b_married > 0 ? value.b_married: '-' }</span>
                        
                        
                          <span  className='mx-2'> अविवाहित-{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                      

                      <div>बहीण :</div>

                <span >विवाहित-{value.s_married > 0 ? value.s_married: '-' }</span>
                <span   className='mx-2'> अविवाहित-{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                  </td>
                  <td style={{fontSize:"14px" ,borderColor:"rgb(250, 99, 18)"}}>{value.exp_housewife === 1 ? 'गृहिणी ' : null}{value.exp_compatible === 1 ? 'अनुरूप ' : null}{value.exp_farmer === 1 ? 'शेती ' : null}{value.exp_serviceman === 1 ? 'नोकरी ' : null}{value.exp_businessman === 1 ? 'व्यवसाय ' : null}<br />
                  
                  </td>

                  <td><div  style={{ justifyContent: "center", display: "flex" }}>
                    <img className='rounded' src={`${memberImagePath}/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "120px", height: "150px", border: "1px solid rgb(250, 99, 18)" }} />
                  </div>
                  </td>
                </tr>
                </>
                      )
                    })}
              </tbody>
                  </Table> */}

                  <Table striped bordered style={{borderColor:"rgb(250, 99, 18)"}}>
                          <thead className='text-center' style={{fontSize:"14px",backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )"}}>
                          <tr>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उमेदवाराचे नाव</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>शिक्षण </th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>नोकरी/व्यवसाय/शेती</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>जन्मतारीख व वेळ</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उंची</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>देह/वर्ण</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>पोटजात/भाऊ/बहिण</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>अपेक्षा</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>फोटो</th>
                            </tr>
                          </thead>
                          <tbody className='text-center' style={{fontSize:"13px"}}>
                          {
                                Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

                                  return (
                                    <>
                            <tr>
                              <td className='text-start' style={{  minWidth: '250px' ,borderColor:"rgb(250, 99, 18)", wordWrap: 'break-word'}}><p style={{ fontWeight: "bold",color:"red"}}>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname} </p>
                              <p style={{ fontSize: "14px",marginTop:"-12px"}}>पत्ता : {' '} {value.mr_address}<br />गाव/ शहर : {value.mr_village}{' '}&nbsp;ता : {value.talukamrname} <br />जि : {value.districtmrname}{' '}&nbsp; रा : {value.statemrname}<br />
                              मो :{' '}{value.mobileno}/{value.mobileno2}
                              </p>
                              </td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '110px', wordWrap: 'break-word'}}>{value.subeducation_name},&nbsp;<br />{value.other_education}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)", maxWidth: '150px', wordWrap: 'break-word'}}>व्यवसाय :{' '}{value.occupation},<br />{value.occupation_detail} <br />
                              वा.प्रा :{' '}{value.amount}<br />
                                { value.farm_g > 0 || value.farm_ac > 0 ? (
                                  <>
                                    शेती -&nbsp;
                                    {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                                    ) : null}
                                    {value.farm_g > 0 ? (
                                      <span >{' '}&nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                                      ) : null
                                    }
                                  </>
                                ) : null}<br />
                                {value.other_property}
                              </td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '40px'}}>{value.dob}<br />{value.birth_time}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '30px'}}>{value.height}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '30px'}}>{value.complextion}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '55px'}}>{value.subcastename}<hr className='m-0 p-1' />
                                      <div >भाऊ :</div> 
                                        <span >विवाहित-{value.b_married > 0 ? value.b_married: '-' }</span>    
                                        <span  className='mx-md-2'> अविवाहित-{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span> 
                                      <div>बहीण :</div>
                                        <span >विवाहित-{value.s_married > 0 ? value.s_married: '-' }</span>
                                        <span   className='mx-md-2'> अविवाहित-{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                              </td>
                              <td style={{fontSize:"14px" ,borderColor:"rgb(250, 99, 18)",maxWidth: '55px'}}>{value.exp_housewife === 1 ? 'गृहिणी ' : null}{value.exp_compatible === 1 ? 'अनुरूप ' : null}{value.exp_farmer === 1 ? 'शेती ' : null}{value.exp_serviceman === 1 ? 'नोकरी ' : null}{value.exp_businessman === 1 ? 'व्यवसाय ' : null}<br />
                              
                              </td>

                              <td style={{maxWidth: '120px'}}><div  style={{ justifyContent: "center", display: "flex" ,}}>
                                <img className='rounded' src={`${memberImagePath}/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "120px", height: "150px", border: "1px solid rgb(250, 99, 18)" }} />
                              </div>
                              </td>
                            </tr>
                            </>
                                  )
                                })}
                          </tbody>
                  </Table>
                </Row>

                  
                  {
                    value.image_status === 2 ?
                    <div className='text-center'> <img style={{height:"80px"}} src={blink} alt="" /> </div>
                  : null}
                  

                  <div className='row p-0'>
                    <div className="col-12 col-md-3"></div>
                    <div className='col-12 col-md-6' style={{ justifyContent: "center", display: "flex" }}>
                      <button type="button" className='rounded m-2' style={{ padding: "4px 15px", backgroundColor: "#ff7b00", border: "0px", color: "white", fontSize: "17px" }} onClick={regFun} > <b>Edit Profile</b></button>

                    </div>
                    <div className="col-12 col-md-3"></div>
                  </div>
                </>
              )
            })}
        </div>
      </Container>
      <div><UserMasterFooter /></div>
    </Container>

  )
}

export default MainPage




