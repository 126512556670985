import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import '../../../UserComponents/UserComponent.css';
import Card from 'react-bootstrap/Card';
import Logo1 from '../../../assets/Acceptable_photos.png';
import Logo2 from '../../../assets/unacceptable_photos1v2.png';
import { toast } from 'react-toastify';


import axios from "axios";
import { RegistrationSelect, RegistrationUpdateProfile, RegistrationUpload, RegistrationUploadAadhar } from '../../../AllApi/UserApi/UserApi';
import { ClassNames } from '@emotion/react';
import { Navigate, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const ThirdPageAdmin = (props) => {

 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const data = JSON.parse(sessionStorage.getItem("status"));
  const [Image, setImage] = useState(null);
  const [AadharImage, setAadharImage] = useState(null);
  const memberdata = JSON.parse(sessionStorage.getItem("memberdata"));
  const userid = memberdata.member_id;
 
  const validateFileSize = () => {
    if (Image) {
      const fileSizeInMB = Image.size / (1024 * 1024); // Convert to MB
      if (fileSizeInMB > 3) {
        toast.error('Image size exceeds the maximum limit of 3MB.', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return false;
      }
    }
    return true;
  };

  const validateAadharFileSize = () => {
    if (!AadharImage) {
      // Image is null
      toast.error('Please upload an Aadhar card image.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
  
    const fileSizeInMB = AadharImage.size / (1024 * 1024); // Convert to MB
    if (fileSizeInMB > 1) {
      toast.error('Aadhar card image size exceeds the maximum limit of 1MB.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
  
    return true;
  };

  
  const NewData = (e) => {
    e.preventDefault();
    setIsLoading(true)

    if (!validateFileSize() || !validateAadharFileSize()) {
      setIsLoading(false);
      return;
    }
  
    let formData = new FormData();
  
    formData.append("file", Image);
    formData.append("member_id", userid);
    formData.append("file1", AadharImage);
  

    const member_id = formData.get("member_id");
    const file = formData.get("file");
    const file1 = formData.get("file1");
   
    
    const finaldata={
      member_id:member_id,
      file:file,
      file1:file1,
    }
   
    axios
      .put(RegistrationUpload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })

      .then((res) => {
      
        setIsLoading(false); 
        if (res.data.message == "UPDATE") {
          
          toast.success('File Uploaded Succesfully!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
          sessionStorage.setItem("status", 6);
          props.onSubmit();

        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error('Failed to upload, try again', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        console.log(err);
      });
  };



  const [update, setUpdate] = useState(false)
  const [updateimage, setupdateimage] = useState(false)
  const [updateaadharimage, setupdateaadharimage] = useState(false)
  //Get Api Call

  const [isEditing, setIsEditing] = useState(false);
 

  const handleEditClick = () => {
   
    
    setIsEditing(true);
    setUpdate(true)
  };
  const [userStatus, setUserStatus] = useState(null)


  useEffect(() => {
    setUserStatus(data)
    if (data >5) {
      
    }

  }, [data])

  

 const seedata =()=>{
  if(data===8){
    navigate('/mainpage')
  }
  else{
  navigate('/payment')
  } 
}
   
const setImgfun=(e)=>{
  setupdateimage(false)
  setImage(e.target.files[0])
}

const setAadharImagefun=(e)=>{
  setupdateaadharimage(false)
  setAadharImage(e.target.files[0])
}


  return (
  <>
    
  <div className='m-0'>
  
  <Container className='container-third rounded p-3 my-3 p-md-5 '>
  <div className='pe-5 ' style={{ display: "flex", justifyContent: "end" }}>
    {

      userStatus >5
        ? (<button
          className='rounded mt-0' style={{ padding: "7px 16px", backgroundColor: "rgb(233, 224, 227)", border: "0px", color: "rgb(134, 28, 18)", marginBottom:"15px",marginTop:"-24px", fontSize: "20px" }} onClick={handleEditClick}> <b>Edit</b></button>

        ) : null
    }
  </div>
    <div className=' rounded shadow p-3 pb-2 py-sm-5' style={{ backgroundColor:"white"}} >
      <Row>
      <Form  onSubmit={NewData} encType="multipart/form-data">
      
      <Form.Group className="mb-3" controlId="formBasicCheckbox">
        <Form.Label><h4 style={{color:"rgb(134, 28, 18)"}}><b>Image <span className='text-dark' style={{fontSize:"15px"}}>(Maximum image upload size 3MB)</span></b></h4></Form.Label>
        <Form.Control
          type="file"
          accept=".jpg,.jpeg,.png"
          onChange={(e) => setImgfun(e)}
          required
          name="file"
        />
     <div  className='pt-4'>
     <div style={{display:"flex",justifyContent:"center"}}>
        <b style={{color:"rgb(134, 28, 18)"}}>Admin reserves the right to approve/reject the photos (फोटो मंजूर/नाकारणे याचा अधिकार आयोजकांकडे राहील)</b>
     </div>
     <Row className='p-3'>
      <Col sm={12} md={4}>
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy" src={updateimage ? `nodeApp/uploads/${Image}`: (Image ? URL.createObjectURL(Image) : '') }  style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image </b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
    
    
      

      <Col sm={12} md={4}>
       <Card className='both text-center' style={{ width: '12rem', border:"0px" ,height:"12rem" }}>
        <Card.Img variant="top" className='acceptable text-center' src={Logo1} style={{ height: '15rem'}} />
         <Card.Body>
          <Card.Text className='text-center'>
            <b>This is acceptable</b>
            <h1 style={{color:"rgb(0, 247, 0)",fontSize:"50px"}}><b><i class="fa-solid fa-check"></i></b></h1>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>

      
      <Col sm={12} md={4}>
       <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" className='unacceptable text-center'  src={Logo2} style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>This is not acceptable </b>
            <h1 style={{color:"red",fontSize:"50px"}}><b><i class="fa-solid fa-xmark"></i></b></h1>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      </div>
      </Form.Group>

{/* ============================== aadhar card ===================== */}
      <Form.Group className="mb-3 mt-3" controlId="formAadharCheckbox">
            <Form.Label>
              <h4 style={{ color: "rgb(134, 28, 18)" }}>
                <b>Aadhar Card Upload</b>
                <span className='text-dark' style={{ fontSize: "15px" }}>(Maximum image upload size 1MB)</span>
              </h4>
            </Form.Label>
            <Form.Control
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={(e) => setAadharImagefun(e)}
              required
              name="aadharFile"
            />
        
     <div  className='pt-4'>
     <Row className='p-3'>
      <Col sm={12} md={4}>
      <Card className='text-center' style={{ width: '13rem' , border:"0px",height:"12rem" }}>
        <Card.Img variant="top" loading="lazy" src={updateaadharimage ? `nodeApp/uploads/${AadharImage}`: (AadharImage ? URL.createObjectURL(AadharImage) : '') }  style={{ height: '8rem'}} />
        <Card.Body>
          <Card.Text className='text-center'>
          <b>Uploaded Image </b>
          </Card.Text>
        </Card.Body>
      </Card>
      </Col>
      </Row>
      </div>
      </Form.Group>








      <div  style={{ display: "flex", justifyContent: "center" }}>
       
              <Button 
                type="submit"
                style={{
                  display: "flex",
                  backgroundColor: "#462f46",
                  border: "none",
                  justifyContent: "center",
                  color:"white"
                }}
                className="my-4 py-2 px-4"
              >
                Submit
              </Button>
          </div>

    </Form>
      </Row>
    </div>

  </Container>
  
  </div>
  </>
  )
}

export default ThirdPageAdmin




