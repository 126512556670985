import React, { useEffect, useState } from 'react';
import axios from 'axios';
import "../BarCodeLogin/LoginQr.css"
import Logo from '../assets/kolLogo.png';
import Logo2 from '../assets/logo2.png';
import Nav from 'react-bootstrap/Nav';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import beforelogo from '../assets/registration-before.png';
import afterlogo from '../assets/login-after.png';
import Barcode from '../assets/QRcode.png';
import { Input } from '@mui/material';
import { Form, Table } from 'react-bootstrap';
import { toast } from "react-toastify";
import AdminMasterFooter from '../CommonComponenets/AdminMasterFooter';
import { BarCodeScanningPerson, memberImagePath, selectregdataforsacn } from '../AllApi/AdminApi/AdminApi';
import { PaymentSuccessApi, WebsiteMessage } from '../AllApi/UserApi/UserApi';

const DataPage = () => {

  const location =useLocation()
  const state = location.state?.Reg_id ;

  const [Userdata, setUserdata] = useState()

  const getUserData = (id) => {


    axios.get(`${selectregdataforsacn}${id}`)
      .then((res) => {
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // =============PaymentRecipt==============
          const [Userdatapay, setUserdatapay] = useState([])

         
          const getUserDatapay = (id) => {


          axios.get(`${PaymentSuccessApi}${id}`)
            .then((res) => {
              
              if (res.data !== null) {
                setUserdatapay(res.data [0])
              }
            })
            .catch((err) => {
              console.log(err)
            })
          }

          useEffect(() => {
            getUserData(state)
            getUserDatapay(state)
          }, [state])

    const navigate = useNavigate()
  

  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); 
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);

  // =================Recipt Brought by ===
  const [BroughtBy,setBroughtBy] = useState()
  const [MobileNum,setMobileNum] = useState()
  const [Person,setPerson] = useState()

  const handleSubmit = (event) => {

    event.preventDefault();

    const saveObj = {
      reg_id:state,
      Candidate_Name:Userdatapay.name,
      Name_Receipt_Brought_By:BroughtBy ,
      Mobile_No:MobileNum ,
      No_of_person:Person ,
    }
    
        axios.post(BarCodeScanningPerson, saveObj)
      .then((res) => {;

        if (res.data == "ERROR") {
          toast.error("Error Occurred !", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
           else if (res.data == 'SAVED') {
              toast.success("Saved Succesfully !", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
              navigate('/scanPage')
            }
        })
        .catch((err) => console.log(err));
     }
   

   //--------------------------CSS---------------------------------
   const style1 = { lineHeight: "15px", fontSize: "14px" }
   const style3 = { lineHeight: "15px", fontSize: "14px" }
   const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
   const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
   const style4 = { display:"flex",lineHeight: "15px", fontSize: "14px",justifyContent:"center" }
   const style5 = { display:"flex",lineHeight: "15px", fontSize: "14px",justifyContent:"center",flexDirection:"column" }

  return (
    <Container fluid className='p-0 m-0 ' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={160} height={120}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभा, वधूवर सूचकमंच, कोल्हापूर विभाग</h5>
            <h3 className='fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={160} height={110}/>
            </div> 
       </Col>
      </Row>  

      <Row className='mb-3 m-0'>
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "7px" }}>
            <img src={beforelogo}  />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <h2 className='mt-1 mb-0 pb-1 text-center' style={{ color: "rgb(134, 28, 18)" }}><b>Function Entry Attendance</b></h2>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
            <img src={afterlogo} />
        </div>
     </Row>

     {/* ============================Name  details...=============== */}
   
           <Row className='m-0 px-md-4' style={{overflowX:"auto"}}>
                <Table striped bordered style={{borderColor:"rgb(250, 99, 18)",}}>
                  <thead className='text-center' style={{fontSize:"14px",backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )"}}>
                   <tr>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उमेदवाराचे नाव</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>शिक्षण </th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>नोकरी/व्यवसाय/शेती</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>जन्मतारीख व वेळ</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उंची</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>देह/वर्ण</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>पोटजात/भाऊ/बहिण</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>अपेक्षा</th>
                      <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>फोटो</th>
                    </tr>
                  </thead>
                  <tbody className='text-center' style={{fontSize:"13px"}}>
                  {
                        Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

                          return (
                            <>
                    <tr>
                      <td className='text-start' style={{  minWidth: '250px' ,borderColor:"rgb(250, 99, 18)"}}><p style={{ fontWeight: "bold",color:"red"}}>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname} </p>
                      <p style={{ fontSize: "14px",marginTop:"-12px"}}>पत्ता : {' '} {value.mr_address}<br />गाव/ शहर : {value.mr_village}{' '}&nbsp;ता : {value.talukamrname} <br />जि : {value.districtmrname}{' '}&nbsp; रा : {value.statemrname}<br />
                      मो :{' '}{value.mobileno}/{value.mobileno2}
                      </p>
                      </td>
                      <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '50px'}}>{value.subeducation_name},&nbsp;<br />{value.other_education}</td>
                      <td style={{borderColor:"rgb(250, 99, 18)", minWidth: '150px'}}>व्यवसाय :{' '}{value.occupation},<br />{value.occupation_detail} <br />
                      वा.प्रा :{' '}{value.amount}<br />
                        { value.farm_g > 0 || value.farm_ac > 0 ? (
                          <>
                            शेती -&nbsp;
                            {value.farm_ac > 0 ? (
                            <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                            ) : null}
                            {value.farm_g > 0 ? (
                              <span >{' '}&nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                              ) : null
                            }
                          </>
                        ) : null}<br />
                        {value.other_property}
                      </td>
                      <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '40px'}}>{value.dob}<br />{value.birth_time}</td>
                      <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '30px'}}>{value.height}</td>
                      <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '70px'}}>{value.complextion}</td>
                      <td style={{borderColor:"rgb(250, 99, 18)",minWidth: '80px'}}>{value.subcastename} <br /> 
                      <div style={{borderBottom:"1px solid grey",padding:"5px 0px"}}></div>
                      <div>भाऊ :</div> 
                              <span >विवाहित-{value.b_married > 0 ? value.b_married: '-' }</span>
                            
                            
                              <span  className='mx-2'> अविवाहित-{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span>
                          

                          <div>बहीण :</div>

                    <span >विवाहित-{value.s_married > 0 ? value.s_married: '-' }</span>
                    <span   className='mx-2'> अविवाहित-{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                      </td>
                      <td style={{fontSize:"14px" ,borderColor:"rgb(250, 99, 18)"}}>{value.exp_housewife === 1 ? 'गृहिणी ' : null}{value.exp_compatible === 1 ? 'अनुरूप ' : null}{value.exp_farmer === 1 ? 'शेती ' : null}{value.exp_serviceman === 1 ? 'नोकरी ' : null}{value.exp_businessman === 1 ? 'व्यवसाय ' : null}<br />
                      
                      </td>

                      <td><div  style={{ justifyContent: "center", display: "flex" }}>
                        <img className='rounded' src={`${memberImagePath}/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "120px", height: "150px", border: "1px solid rgb(250, 99, 18)" }} />
                      </div>
                      </td>
                    </tr>
                    </>
                          )
                        })}
                  </tbody>
                </Table>
            </Row>

            {/* //------------------payment-------------// */}
        <Row className='p-0 m-0 py-4 '>
          <Col style={{textAlign:"center"}}>
            <h6><b style={{ color:"red" }}>Please carry the print of this Confirmation Receipt at the time of Melava, Without Receipt entry will not be allowed to Melava.</b></h6>

              <h6><b style={{fontFamily:"cursive", color:"red" }}>कृपया मेळाव्याच्या वेळी या पावतीची प्रिंट सोबत ठेवा, पावतीशिवाय मेळाव्याला प्रवेश दिला जाणार नाही.</b></h6>
          </Col>
        </Row>

        <Row className='m-0'>
            <Col md={3}></Col>

            <Col sm={12} md={6}>
              <Row>
                <Col>
                  <b>Application Id :</b>
                </Col>
                <Col>
                  <p>Reg. No. APP{websiteMessage.melava_year}/{Userdatapay.reg_id}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Payment Date & Time :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.pay_date}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Customer Name :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.name}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Melava Type :</b>
                </Col>
                <Col>
                  <p> 
                     <span style={{backgroundColor:"yellow",padding:"5px"}}>
                      {Userdatapay.melava_type === 1 && 'First Marriage Melava'}
                      {Userdatapay.melava_type === 2 && 'Vidhva-Vidhoor Melava'}
                      {Userdatapay.melava_type === 3 && 'Both Melava'}
                      </span>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <b>Amount :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.pay_amount}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Email Id :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.email_id}</p>
                </Col>
              </Row>

              <Row>
                <Col>
                  <b>Transaction Id :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.taxid}</p>
                </Col>
              </Row>


              <Row>
                <Col>
                  <b>Mobile No :</b>
                </Col>
                <Col>
                  <p>{Userdatapay.mobileno}</p>
                </Col>
              </Row>

            </Col>

            <Col md={3}></Col>
        </Row>


        <Row className='m-0 pt-4 pb-5' >
         <Form onSubmit={handleSubmit}>
            <Row className='px-5'>
              <Col md={1}></Col>
              <Col md={10} >
              <Row style={{display:"flex",justifyContent:"space-between"}}>
                  <Col md={5} className='pt-2'>
                      <Form.Control className='py-2 px-3 rounded shadow' md={5} placeholder='Name-Receipt Brought By'
                       value={BroughtBy}
                       onChange={(e) => setBroughtBy(e.target.value)}
                       required></Form.Control>
                  </Col>
                  <Col md={5} className='pt-2'>
                       <Form.Control
                         className='py-2 px-3 rounded shadow' md={5}
                         placeholder='Mobile No.' 
                         value={MobileNum}
                         onChange={(e) => setMobileNum(e.target.value)}
                         required
                         pattern="[0-9]{10}"
                         maxlength="10"
                         title='Please enter 10 digit num.'
                         type="tel"
                         id="mobileNumber"
                         ></Form.Control>
                  </Col>
                  <Col md={2} className='pt-2'>
                     <Form.Select className='py-2 px-3 rounded shadow' md={2} placeholder='No. Of Person' required
                     value={Person}
                     onChange={(e) => setPerson(e.target.value)}
                     >
                        <option value="">No. of Person</option>
                        <option >1</option>
                        <option >2</option>
                        <option >3</option>
                        <option >4</option>
                     </Form.Select>
                  </Col>
                </Row>
              </Col>
              <Col md={1}></Col>
            </Row>
            <Row className='m-0 pb-md-3' >
              <Col className='py-4' style={{ textAlign: "center", paddingTop: "10px", }}>
                    <button type="submit" className=' rounded' style={{ padding: "7px 19px", backgroundColor: "#462f46", border: "0px", color: "white", fontSize: "20px" }} > Entry Save
                    </button>
              </Col>
            </Row>
           </Form>
         </Row>

         {/* -----------------footer  --------------------------- */}
         <AdminMasterFooter/>

    </Container>
  )
}

export default DataPage
