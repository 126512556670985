import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import UserMasterHeader from '../UserComponents/UserCommonComponent/UserMasterHeader'
import UserMasterFooter from '../UserComponents/UserCommonComponent/UserMasterFooter'
import axios from 'axios'
import { PaymentSuccessApi } from '../AllApi/UserApi/UserApi'
import { useLocation, useNavigate } from 'react-router-dom'
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';

const PaymentFail = () => {

  const navigate=useNavigate()
  const [Userdata, setUserdata] = useState([])
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);


  const member_id = searchParams.get('abc');

  const getUserData = (member_id) => {


  axios.get(`${PaymentSuccessApi}${member_id}`)
    .then((res) => {
      if (res.data.json !== null) {
        setUserdata(res.data)
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

useEffect(() => {
  getUserData(member_id)
}, [member_id])

    const TryAgainFun = (()=>{
      navigate('/payment2')
    })
    
    const logoutFun = () => {
      sessionStorage.clear()
      navigate("/");    
    }
  return (
    <Container fluid  className='m-0 p-0'>
    <div className='m-0 p-0'>
    <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={5} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी २०२३</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>

        <Col md={1} sm={12} style={{display:"flex",justifyContent:"start"}}>
            <Nav.Link  onClick={logoutFun}>Log-Out <i class="fa-solid fa-right-from-bracket"></i></Nav.Link>
        </Col>      
      </Row> 

    </Container>
    </div>


    <Row className='m-0 mt-1 my-3 my-md-5 mx-3 ' >
    <Col sm={2} md={4}></Col>

    <Col sm={8} md={4} 
    style={{backgroundColor:"rgb(248, 248, 268)"}} className='p-2 p-md-5 rounded shadow'>

    <h1 style={{color:"red",fontSize:"50px"}}
     className='text-center '>
     <b ><i style={{borderRadius:"50px",border:"2px solid red"}} 
     class="fa-solid fa-xmark  p-2 px-3"></i></b></h1>

    <h3 style={{marginTop:"-10px"}} className='text-center'><b>Payment Failed !!</b></h3>

    <p className='pt-3'>Hii,{''} <span><b>{Userdata.fname} </b></span></p>

    <p>We can't processed your payment of <b>Rs. {Userdata.amount}</b> </p>

    <p><b>Transaction ID :</b> {Userdata.tnxid}</p>

    <p><b>Failure Reason :{Userdata.respons}</b></p>

    <div style={{display:"flex",justifyContent:"center"}}>
         <button className='text-center py-2 px-3 rounded border-0 mt-3 text-light' style={{backgroundColor:"red"}} onClick={TryAgainFun} ><b>Try Again</b></button>
    </div>
    </Col>
 
    <Col sm={2} md={4}></Col>
    </Row> 
    
    <div><UserMasterFooter/></div>

    </Container>
  )
}

export default PaymentFail