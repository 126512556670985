import React, { useEffect, useState } from 'react'
import { Col, Container, Nav, Row } from 'react-bootstrap'
import UserMasterHeader from '../UserComponents/UserCommonComponent/UserMasterHeader'
import UserMasterFooter from '../UserComponents/UserCommonComponent/UserMasterFooter'
import { PaymentSuccessApi, PaymentUpdate } from '../AllApi/UserApi/UserApi'
import axios from 'axios'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import Logo from '../assets/Mainlogo.jpg';
import Logo2 from '../assets/logo2.png';

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const userdata = JSON.parse(sessionStorage.getItem('userdata'));
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const member_id = searchParams.get('abc');

  const [Userdata, setUserdata] = useState([]);

  const updatePayment = (user) => {
    const saveObj = {
      reg_id: user.reg_id,
      payment: 1,
      pay_amount: user.amount,
    };
    axios
      .post(PaymentUpdate, saveObj)
      .then((res) => {
        if (res.data === 'UPDATED') {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserData = (memberId) => {
    axios
      .get(`${PaymentSuccessApi}${memberId}`)
      .then((res) => {
        if (res.data !== null) {
          setUserdata(res.data);
          updatePayment(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (member_id) {
      getUserData(member_id);
    }
  }, [member_id]);

  const setStatusFun = () => {
    navigate('/');
    sessionStorage.clear()
  };

  const setReceiptFun = () => {
    navigate('/paymentRecipt', { state: { member_id } });
  };

  const logoutFun = () => {
    sessionStorage.clear()
    navigate('/');
  };

  return (
    <>
      <Container fluid className='m-0 p-0'>
        <div className='m-0 p-0'>
        <div >
        <Container fluid className='p-0 m-0' style={{boxSizing:"border-box"}}>
      <Row className='header m-0 p-0 py-1'>
        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col> 
       
       <Col md={6} sm={12} style={{display:"flex",justifyContent:"center"}}>
        <div className='text-center '>
            <h5 className='fw-bolder'>दक्षिण भारत जैन सभेचे</h5>
            <h3 className='fw-bolder'>शेठ रा. ध. दावडा दिगंबर जैन बोर्डिंग, सांगली </h3>
            <h5 className='fw-bolder'>जैन वधू - वर नोंदणी २०२३</h5>
        </div>
        </Col> 

        <Col md={3} sm={12} style={{display:"flex",justifyContent:"center"}}>
            <div>
                <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
            </div> 
       </Col>


      </Row> 

    </Container> 
    </div>
        </div>

        <div className='pe-md-5 pe-2 py-3' style={{ display: "flex", justifyContent: "end" }}>
          <button className='py-2 px-3 rounded' style={{ backgroundColor: "red", color: "white", border: "0px" }} onClick={logoutFun} ><b><Nav.Link className=' mx-2 text-white'>Log-Out</Nav.Link></b></button> &nbsp; &nbsp;
        </div>

        <Row className='m-0 mt-0 mb-3 my-md-5 mx-3 ' >
          <Col sm={2} md={4}></Col>

          <Col sm={8} md={4} style={{ backgroundColor: "rgb(248, 248, 268)" }} className='p-2 p-md-5 rounded shadow'>
            <h1 style={{ color: "rgb(0, 247, 0)", fontSize: "50px" }} className='text-center '><b ><i style={{ borderRadius: "50px", border: "2px solid rgb(0, 247, 0)" }} class="fa-solid fa-check  p-2 px-3"></i></b></h1>
            <h3 style={{ marginTop: "-10px" }} className='text-center'><b>Payment Successful !!</b></h3>
            <p className='pt-3'>Hii,{''} <span><b>{Userdata.fname}</b></span></p>
            <p>We have processed your payment of <b>Rs. {Userdata.amount}</b> successfully.</p>
            <p><b>Transaction ID </b>: {Userdata.tnxid}.</p>
            <marquee scrollamount="9">
            <h6  className='pt-3'><b style={{color:'red'}} className='text-center '>Your Receipt Has Been Sent To Your Registered Mail Id !!</b></h6>
            </marquee>
          </Col>



          <Col sm={2} md={4}></Col>
        </Row>


        <div><UserMasterFooter /></div>

      </Container>
    </>
  )
}

export default PaymentSuccess