import React, { useEffect, useState, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Col, Container, Row, Table } from 'react-bootstrap';
import UserMasterHeader from './UserCommonComponent/UserMasterHeader';
import { useLocation, useNavigate } from 'react-router-dom';
import { BarCodeRecipt, PaymentSuccessApi, RegistrationSelect, WebsiteMessage } from '../AllApi/UserApi/UserApi';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { saveAs } from 'file-saver';
import Logo from '../assets/logo2.png';
import Logo2 from '../assets/kolLogo.png';
import Nav from 'react-bootstrap/Nav';
import blink from '../assets/photo_rejected.gif'
import Navbar from 'react-bootstrap/Navbar';
import { set } from 'react-hook-form';
import { memberImagePath } from '../AllApi/AdminApi/AdminApi';


const PaymentRecipt = () => {

 useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  const location = useLocation();

  const state = location.state;
 
  const userdata = JSON.parse(sessionStorage.getItem("userdata"));
  const mid = userdata.id
  const [member_id, setmember_id] = useState(mid)
  const reg_id = userdata.reg_id;
  const [spinnerstatus, setspinnerstatus] = useState(false)
  
  useEffect(() => {
    if (state === undefined) {
      setmember_id(mid)
    }
    else {
      setmember_id(state)
    }

  }, [state, mid])

  const [barcode, setbarcode] = useState()
  const [userid, setuserid] = useState()
  // ---------------print-----
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Payment_Recipt',
  })

  const pdfOptions = {
    margin: 10,
    filename: 'Payment_Recipt.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 2 },
    jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    pagebreak: { before: '.page-break' },
  };

  // Select the content to print (excluding buttons)
  const contentToPrint = document.getElementById('content-to-print');

  // Generate the PDF
  html2pdf().from(contentToPrint).set(pdfOptions).outputPdf((pdf) => {
  
    saveAs(pdf, 'Payment_Recipt.pdf');
  });





  const [Userdata, setUserdata] = useState([])

  useEffect(() => {
    Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {
      setuserid(value.reg_id)
    }
    )

  }, [Userdata])


  const getUserData = (member_id) => {


    axios.get (`${RegistrationSelect}${member_id}`)
      .then((res) => {
        
        if (res.data.json !== null) {
          setUserdata(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (member_id) {
      setspinnerstatus(false)
      getUserData(member_id)
      
        }
    else{
      setspinnerstatus(true)
    }

  }, [member_id])

  // -----------handle checkbox-----------
  const navigate = useNavigate()

  // =============PaymentRecipt==============
  const [Userdatapay, setUserdatapay] = useState([])

  
  const getUserDatapay = (reg_id) => {


    axios.get(`${PaymentSuccessApi}${reg_id}`)
      .then((res) => {

        if (res.data !== null) {

          setUserdatapay(res.data[0])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if(reg_id){

      setspinnerstatus(false)
    getUserDatapay(reg_id)}
    else{
      setspinnerstatus(true)
    }
  }, [reg_id])

  //=========BarCode===========


  const BarCodeReciptPay = (userid) => {
    const regdata = {

      reg_id: userid

    };

    axios
      .post(BarCodeRecipt, regdata)
      .then((res) => {
        if (res.data !== null) {

          setbarcode(res.data.barcodeDataUri)

        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if(userid){

      setspinnerstatus(false)
    BarCodeReciptPay(userid)
    }else{
      setspinnerstatus(true)
    }
  }, [userid])

  // =====================Header=====================


  const logoutFun = () => {
    sessionStorage.removeItem("userdata");
    sessionStorage.clear()
    navigate("/");
  }

  useEffect(() => {
    let token = sessionStorage.getItem("token");
    let userdata = sessionStorage.getItem("userdata");

    if (token == null || userdata == null) {
      sessionStorage.clear()
      navigate("/")
    }

  }, [])
  const [websiteMessage, setWebsiteMessage] = useState([]); 
  const fetchWebsiteMessage = async () => {
    try {
      const response = await fetch(WebsiteMessage);
      const data = await response.json();
      setWebsiteMessage(data[0]); 
    } catch (error) {
      console.error('Error fetching website message:', error);
    }
  };

  useEffect(() => {
    fetchWebsiteMessage();
  }, []);

  //--------------------------CSS---------------------------------
  const style1 = { lineHeight: "15px", fontSize: "14px" }
  const style3 = { lineHeight: "15px", fontSize: "14px" }
  const style = { fontSize: "14px", width: "100%", padding: "5px", backgroundColor: "#f796ec", fontWeight: "bold", borderRadius: "10px 10px 0px 0px", border: "1px solid white" }
  const style2 = { border: "1px solid #f796ec", padding: "8px 5px", alignItems: "center", height: "88px", margin: "0px 1px 0px 1px", textAlign: "start" }
  const style4 = { display: "flex", lineHeight: "15px", fontSize: "14px", justifyContent: "center" }
  const style5 = { display: "flex", lineHeight: "15px", fontSize: "14px", justifyContent: "center", flexDirection: "column" }


  return (
    <>

      <Container fluid className='m-0 p-0 '>
       
        
        <div className='pe-md-5 pe-2 py-3' style={{ display: "flex", justifyContent: "end" }}>
          <button className='py-2 px-4 rounded' style={{ backgroundColor: "red", color: "white", border: "0px" }} ><b><Nav.Link href="/#/mainPage" className=' mx-2 text-white'>Profile</Nav.Link></b></button> &nbsp; &nbsp;
          <button className='py-2 px-4 rounded' style={{ backgroundColor: "red", color: "white", border: "0px" }} onClick={handlePrint}><b>Download Receipt</b></button>
          <button className='py-2 px-4 rounded mx-2' style={{ backgroundColor: "red", color: "white", border: "0px" }}><Nav.Link href="/"  onClick={logoutFun}><b >Log-Out</b></Nav.Link></button>
        </div>
        <div ref={componentRef} id="content-to-print" style={{ width: "100%", height: window.innerHeight }}>
          <div className='m-0  rounded'  >
            <div >
              <Container fluid className='p-0 m-0' style={{ boxSizing: "border-box" }}>
              <Row className='header m-0 p-0 py-1'>
              <Col md={2} sm={1}></Col>
                <Col md={2} sm={2} style={{display:"flex",justifyContent:"center"}}>
                    <div>
                        <img src={Logo2} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
                    </div> 
              </Col> 
              
              <Col md={4} sm={6} style={{display:"flex",justifyContent:"center",color:"rgb(57, 29, 28)"}}>
              <div className=' text-center' style={{fontFamily: "'Gotu', sans-serif",
        fontFamily: "'Noto Sans Devanagari', sans-serif"}}>
                      <h6 className='text-center fw-bolder'>दक्षिण भारत जैन सभा</h6>
                      <h5 className='text-center fw-bolder'>दिगंबर जैन बोर्डिंग, कोल्हापूर</h5>
                      <h6 className='text-center fw-bolder'>जैन वधू - वर नोंदणी {websiteMessage.melava_year}</h6>
                    </div>
                </Col> 

                <Col md={2} sm={2} style={{display:"flex",justifyContent:"center"}}>
                    <div>
                        <img src={Logo} style={{mixBlendMode:"darken"}} alt="" width={100} height={100}/>
                    </div> 
              </Col>
              <Col md={2} sm={1}></Col>
      </Row>

              </Container>
            </div>
            <div className='row p-0 m-0'>
              <div className='col-12' style={{ display: "flex", justifyContent: "center" }}>
                <div className='text-center'>
                <h5 className='fw-bolder d-md-flex' >
                  <span>Confirmation Receipt &nbsp;</span>
                  {Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {
                    const melavaMessage = value.melava_type === 1 
                      ? '(First Marriage Melava)' 
                      : value.melava_type === 2 
                      ? '(Vidhava Vidhoor Melava)' 
                      : value.melava_type === 3 
                      ? '(Both Melava)' 
                      : 'Unknown';
                    
                    return (
                      <div key={index}>
                        {melavaMessage}
                      </div>
                    );
                  })}
                </h5>




                  <h6 className='fw-bolder'><b>{websiteMessage.first_melava_no} वा भव्य जैन वधूवर मेळावा</b></h6>
                  {/* <h6 className='fw-bolder'><b>रविवार दि. 3 डिसेंबर 2023 रोजी सकाळी 9 ते सायं 5 पर्यंत </b></h6> */}
                </div>
              </div>
            </div>

            <Row className='m-0 px-md-4'  >

                  <Table striped bordered style={{borderColor:"rgb(250, 99, 18)"}}>
                          <thead className='text-center' style={{fontSize:"14px",backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )"}}>
                          <tr>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उमेदवाराचे नाव</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>शिक्षण </th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>नोकरी/व्यवसाय/शेती</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>जन्मतारीख व वेळ</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>उंची</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>देह/वर्ण</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>पोटजात/भाऊ/बहिण</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>अपेक्षा</th>
                              <th style={{backgroundImage: "linear-gradient( 180.5deg, rgba(214, 234, 248)  12.3%,  rgba(33, 97, 140) 92% )",color:"black",borderColor:"rgb(250, 99, 18)"}}>फोटो</th>
                            </tr>
                          </thead>
                          <tbody className='text-center' style={{fontSize:"13px"}}>
                          {
                                Userdata && Array.isArray(Userdata) && Userdata.map((value, index) => {

                                  return (
                                    <>
                            <tr>
                              <td className='text-start' style={{  minWidth: '250px' ,borderColor:"rgb(250, 99, 18)", wordWrap: 'break-word'}}><p style={{ fontWeight: "bold",color:"red"}}>{value.mr_fname}/{' '}{value.mr_fatherfname}{' '}{value.mr_fathermname}{' '} {value.mr_fatherlname} </p>
                              <p style={{ fontSize: "14px",marginTop:"-12px"}}>पत्ता : {' '} {value.mr_address}<br />गाव/ शहर : {value.mr_village}{' '}&nbsp;ता : {value.talukamrname} <br />जि : {value.districtmrname}{' '}&nbsp; रा : {value.statemrname}<br />
                              मो :{' '}{value.mobileno}/{value.mobileno2}
                              </p>
                              </td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '110px', wordWrap: 'break-word'}}>{value.subeducation_name},&nbsp;<br />{value.other_education}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)", maxWidth: '150px', wordWrap: 'break-word'}}>व्यवसाय :{' '}{value.occupation},<br />{value.occupation_detail} <br />
                              वा.प्रा :{' '}{value.amount}<br />
                                { value.farm_g > 0 || value.farm_ac > 0 ? (
                                  <>
                                    शेती -&nbsp;
                                    {value.farm_ac > 0 ? (
                                    <span> {value.farm_ac}&nbsp; एकर{' '}</span>
                                    ) : null}
                                    {value.farm_g > 0 ? (
                                      <span >{' '}&nbsp; {value.farm_g} &nbsp; गुंठे{' '}</span>
                                      ) : null
                                    }
                                  </>
                                ) : null}<br />
                                {value.other_property}
                              </td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '40px'}}>{value.dob}<br />{value.birth_time}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '30px'}}>{value.height}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '30px'}}>{value.complextion}</td>
                              <td style={{borderColor:"rgb(250, 99, 18)",maxWidth: '55px'}}>{value.subcastename}<hr className='m-0 p-1' />
                                      <div >भाऊ :</div> 
                                        <span >विवाहित-{value.b_married > 0 ? value.b_married: '-' }</span>    
                                        <span  className='mx-md-2'> अविवाहित-{value.b_unmarried > 0 ? value.b_unmarried: ' -'}</span> 
                                      <div>बहीण :</div>
                                        <span >विवाहित-{value.s_married > 0 ? value.s_married: '-' }</span>
                                        <span   className='mx-md-2'> अविवाहित-{value.s_unmarried > 0 ? value.s_unmarried: '-'}</span>
                              </td>
                              <td style={{fontSize:"14px" ,borderColor:"rgb(250, 99, 18)",maxWidth: '55px'}}>{value.exp_housewife === 1 ? 'गृहिणी ' : null}{value.exp_compatible === 1 ? 'अनुरूप ' : null}{value.exp_farmer === 1 ? 'शेती ' : null}{value.exp_serviceman === 1 ? 'नोकरी ' : null}{value.exp_businessman === 1 ? 'व्यवसाय ' : null}<br />
                              
                              </td>

                              <td style={{maxWidth: '120px'}}><div  style={{ justifyContent: "center", display: "flex" ,}}>
                                <img className='rounded' src={`${memberImagePath}/${value.image}`} alt={`${value.mr_fname}'s Photo`} style={{ width: "120px", height: "150px", border: "1px solid rgb(250, 99, 18)" }} />
                              </div>
                              </td>
                            </tr>
                            </>
                                  )
                                })}
                          </tbody>
                  </Table>

          </Row>

            {/* //------------------payment-------------// */}
            <Row className='p-0 m-0 py-4 '>
              <Col style={{ textAlign: "center" }}>
                <h6><b style={{ color: "red" }}>Please carry the print of this Confirmation Receipt at the time of Melava, Without Receipt entry will not be allowed to Melava.</b></h6>

                <h6><b style={{ fontFamily: "cursive", color: "red" }}>कृपया मेळाव्याच्या वेळी या पावतीची प्रिंट सोबत ठेवा, पावतीशिवाय मेळाव्याला प्रवेश दिला जाणार नाही.</b></h6>
              </Col>
            </Row>
            {spinnerstatus === true ? (
              <>
                <div class="spinner-grow text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-secondary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-success" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-info" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-light" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-dark" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </>
            ) : null
            }

            <Row className='m-0'>
              <Col md={3}></Col>

              <Col sm={12} md={6}>
                <Row>
                  <Col>
                    <b>Application Id :</b>
                  </Col>
                  <Col>
                    <p>Reg. No. SUP{websiteMessage.melava_year}/{Userdatapay.reg_id}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <b>Payment Date & Time :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.pay_date}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <b>Customer Name :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.name}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <b>Amount :</b>
                  </Col>
                  <Col>
                    <p> Rs. {Userdatapay.pay_amount}</p>
                  </Col>
                </Row>


                <Row>
                  <Col>
                    <b>Email Id :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.email_id}</p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <b>Transaction Id :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.taxid}</p>
                  </Col>
                </Row>


                <Row>
                  <Col>
                    <b>Mobile No :</b>
                  </Col>
                  <Col>
                    <p>{Userdatapay.mobileno}</p>
                  </Col>
                </Row>

              </Col>

              <Col md={3}></Col>
            </Row>

            <Row className='m-0 pb-5 pt-2'>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <img src={barcode} ></img>
              </Col>
            </Row>


          </div>
        </div>


      </Container>

    </>
  )
}

export default PaymentRecipt




